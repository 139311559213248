<template>
	<v-flex v-if="isAccountantAdmin">
		<v-layout row>
			<v-spacer></v-spacer>
			<w-btn icon="add" @click="openDialog()">{{ $t('user.actions.add.collaborator') }}</w-btn>
			<w-dialog v-model="dialog" max-width="600px" :title="$t('user.actions.add.collaborator')" @close="closeDialog()">
				<TeamManagerUserCreationForm v-if="showForm" :clients="clients" :roles="roles" @saved="onSaved($event)" />
			</w-dialog>
		</v-layout>
	</v-flex>
</template>

<script>
import TeamManagerModuleGuard from '@/mixins/ModulesGuards/TeamManager/TeamManagerModuleGuard'

export default {
	name: 'TeamManagerAddBtn',
	components: {
		TeamManagerUserCreationForm: () => ({
			component: import('@/components/TeamManager/TeamManagerUserCreationForm')
		})
	},
	mixins: [TeamManagerModuleGuard],
	props: {
		clients: {
			required: true,
			type: Array
		},
		roles: {
			required: true,
			type: Array
		},
		isAccountantAdmin: {
			required: true,
			type: Boolean
		}
	},
	data () {
		return {
			accountant: { send_mail: true },
			dialog: false,
			showForm: false
		}
	},
	methods: {
		closeDialog: function () {
			this.dialog = false
			this.showForm = false
		},
		onSaved: function (user) {
			this.$emit('add', user)
			this.closeDialog()
		},
		openDialog: function () {
			this.showForm = true
			this.$nextTick(() => {
				this.dialog = true
			})
		}
	}
}
</script>
